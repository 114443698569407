import React from "react";
import "./WhatsAppButton.css";
import { FaPhone, FaWhatsapp  } from "react-icons/fa";

function WhatsAppButton() {
  return (
    <>
      <a
        href="tel:+19085405092"
        title="Call us"
        className="call flex flex-col justify-center items-center animate-pulse"
      >
        <FaPhone size={22} className="call-icon" />
      </a>
      <a
        href="https://wa.link/83b82q"
        title="WhatsApp"
        target="_blank" 
        rel="noreferrer"
        className="whatsapp flex flex-col justify-center items-center animate-pulse"
      >
        <FaWhatsapp className="whatsapp-icon" />
      </a>
    </>
  );
}

export default WhatsAppButton;

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { SectionHeading as HeadingTitle } from "../components/misc/Headings.js";
import { ReactComponent as SvgDecoratorBlob1 } from "../images/svg-decorator-blob-1.svg";
import { ReactComponent as SvgDecoratorBlob2 } from "../images/svg-decorator-blob-3.svg";

const Container = tw.div`relative`;
const Content = tw.div`max-w-screen-2xl mx-auto py-20 lg:py-24`;
const ThreeColumn = tw.div`flex flex-col items-center mt-10 lg:mt-24 gap-6 lg:items-center lg:flex-row`;
const Column = tw.div` w-full lg:w-full`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Card = tw.div`lg:mx-0 w-full md:max-w-lg mx-auto lg:w-full relative`;
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}");`,
  tw`bg-cover bg-center h-56 sm:h-48 lg:h-64 rounded rounded-xl relative overflow-hidden`,
  `
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: inherit;
      filter: brightness(50%);
      z-index: 0;
    }
  `
]);

const Title = tw.h3`mt-2 leading-relaxed font-bold text-xl `;
const Description = tw.p`text-sm md:text-base`;
const TextContainer = tw.div`absolute bottom-[20px] text-white font-light text-left px-[20px]`;

const DecoratorBlob1 = tw(
  SvgDecoratorBlob1
)`-z-10 absolute bottom-0 right-0 w-48 h-48 transform translate-x-40 -translate-y-8 opacity-25`;
const DecoratorBlob2 = tw(
  SvgDecoratorBlob2
)`-z-10 absolute top-0 left-0 w-48 h-48 transform -translate-x-32 translate-y-full opacity-25`;

export default () => {
  const services = [
    {
      imageSrc:
        "https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Commercial Cleaning",
      description: "Maintain a clean, professional image with customized cleaning plans for your office, retail space, or financial institution."
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1601160458000-2b11f9fa1a0e?q=80&w=2070&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Medical Cleaning",
      description: "Hygiene is critical in medical environments. We ensure effective disinfection for your staff and patients. Perfect for dentistry and physical therapy facilities."
    },
    {
      imageSrc:
        "https://images.unsplash.com/photo-1628689814107-61cff402f7cc?q=80&w=1974&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D",
      title: "Electrostatic Disinfection",
      description: "Eliminate harmful germs and bacteria with our state-of-the-art electrostatic disinfection. Ideal for schools, post-construction sites, warehouses and fitness centers."
    }
  ];
  return (
    <Container id="services">
      <Content>
        <HeadingInfoContainer>
          <HeadingTitle>Our services</HeadingTitle>
          <HeadingDescription>Discover tailored cleaning solutions designed to keep your spaces spotless, hygienic, and welcoming. Your business deserves nothing but the best.</HeadingDescription>
        </HeadingInfoContainer>
        <ThreeColumn>
          {services.map((service, index) => (
            <Column key={index}>
              <Card>
                <Image imageSrc={service.imageSrc} title={service.description}/>
                <TextContainer>
                  <Title>{service.title}</Title>
                  <Description>{service.description}</Description>
                </TextContainer>
              </Card>
            </Column>
          ))}
        </ThreeColumn>
      </Content>
      <DecoratorBlob1 />
      <DecoratorBlob2 />
    </Container>
  );
};

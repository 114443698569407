import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";


import Hero from "sections/Hero.js";
import AboutUs from "sections/AboutUs.js";
import FeatureStats from "sections/Stats.js";
import FAQ from "sections/Faq.js";
import Services from "sections/Services";
import Form from "sections/Form";
import Testimonials from "sections/Testimonials";
import OurWork from "sections/OurWork";
import Footer from "sections/Footer";

const HighlightedText = tw.span`text-primary-700`

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FeatureStats />
      {/* Services */}
      <Services />
      {/* Sección para empleados */}
      <AboutUs
        heading={<>We care about <br></br> <HighlightedText>our people</HighlightedText></>}
      />
      {/* Antes y despues */}
      <OurWork />
      {/* Testimonios */}
      <Testimonials />
      <FAQ
        heading={<>Any <HighlightedText>Questions ?</HighlightedText></>}
      />
      <Form />
      {/* Footer */}
      <Footer />
    </AnimationRevealPage>
  );
}

import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "../images/logo-full.png";
import { ReactComponent as FacebookIcon } from "../images/facebook-icon.svg";
import { ReactComponent as TwitterIcon } from "../images/twitter-icon.svg";
import { ReactComponent as YoutubeIcon } from "../images/youtube-icon.svg";
import { scrollConfig } from "components/headers/light";
import { Link } from "react-scroll";

const Container = tw.div`relative bg-gray-200 -mx-8 -mb-8 px-8`;
const FiveColumns = tw.div`max-w-screen-xl mx-auto py-16 lg:py-20 flex flex-wrap justify-between`;

const Column = tw.div`md:w-1/5`;
const WideColumn = tw(Column)`text-center md:text-left w-full md:w-2/5 mb-10 md:mb-0`;

const ColumnHeading = tw.h5`font-bold text-primary-700`;

const LinkList = tw.ul`mt-4 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const LinkFooter = styled(Link)`
  ${tw`
    border-b-2 cursor-pointer border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300
  `}
`;

const LinkNormalFooter = tw.a`border-b-2 border-transparent hocus:text-primary-500 hocus:border-primary-500 pb-1 transition duration-300`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;
const LogoImg = tw.img`w-1/2`;

const CompanyDescription = tw.p`mt-4 max-w-xs font-medium text-gray-700 text-sm mx-auto md:mx-0 md:mr-4 `;

const SocialLinksContainer = tw.div`mt-4 `;
const SocialLink = styled.a`
  ${tw`cursor-pointer inline-block p-2 rounded-full bg-gray-700 text-gray-100 hover:bg-gray-900 transition duration-300 mr-4`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <FiveColumns>
        <WideColumn>
          <LogoContainer>
            <LogoImg alt="Metropolitan Cleaning Services logo. New York & New Jersey" src={LogoImage} />
          </LogoContainer>
          <CompanyDescription>
            Commercial Cleaning services in New York and New Jersey
          </CompanyDescription>
          <SocialLinksContainer>
            <SocialLink href="https://facebook.com" title="facebook.com">
              <FacebookIcon />
            </SocialLink>
            <SocialLink href="https://twitter.com" title="twitter.com">
              <TwitterIcon />
            </SocialLink>
            <SocialLink href="https://youtube.com" title="youtube.com">
              <YoutubeIcon />
            </SocialLink>
          </SocialLinksContainer>
        </WideColumn>
        <Column>
          <ColumnHeading>Quick Links</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <LinkFooter title="services" to="services" {...scrollConfig}>Services</LinkFooter>
            </LinkListItem>
            <LinkListItem>
              <LinkFooter title="about" to="about" {...scrollConfig}>About us</LinkFooter>
            </LinkListItem>
            <LinkListItem>
              <LinkFooter title="work" to="work" {...scrollConfig}>Our Work</LinkFooter>
            </LinkListItem>
            <LinkListItem>
              <LinkFooter title="faq" to="faq" {...scrollConfig}>FAQ</LinkFooter>
            </LinkListItem>
            <LinkListItem>
              <LinkFooter title="quote" to="quote" {...scrollConfig}>Get a free quote</LinkFooter>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Legal</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <LinkNormalFooter title="Privacy Policy" href="/privacy-policy">Privacy Policy</LinkNormalFooter>
            </LinkListItem>
            <LinkListItem>
              <LinkNormalFooter title="Terms of Service" href="/terms">Terms of Service</LinkNormalFooter>
            </LinkListItem>
          </LinkList>
        </Column>
        <Column>
          <ColumnHeading>Contact</ColumnHeading>
          <LinkList>
            <LinkListItem>
              <LinkNormalFooter href="tel:+19085405092" title="Call us">Call us</LinkNormalFooter>
            </LinkListItem>
            <LinkListItem>
              <LinkNormalFooter href="mailto:info@metropolitancleaning.net" title="Email us">Send us a message</LinkNormalFooter>
            </LinkListItem>
          </LinkList>
        </Column>
      </FiveColumns>
    </Container>
  );
};

import React, { useEffect } from "react";
import { motion } from "framer-motion";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import useAnimatedNavToggler from "../../helpers/useAnimatedNavToggler.js";
import { Link } from "react-scroll";

import logo from "../../images/logo-full.png";
import { ReactComponent as MenuIcon } from "feather-icons/dist/icons/menu.svg";
import { ReactComponent as CloseIcon } from "feather-icons/dist/icons/x.svg";

const Header = tw.header`
  flex justify-between items-center
  max-w-screen-xl mx-auto
`;

export const NavLinks = tw.div`inline-block`;

export const scrollConfig = {
  smooth: true,
  duration: 0,
  offset: -70,
  hashSpy: false,
  isDynamic: true,
};

export const ScrollNavLink = styled(Link)`
  ${tw`
    text-lg my-2 lg:text-base lg:mx-6 lg:my-0
    font-semibold tracking-wide transition duration-300
    pb-1 border-b-2 border-transparent hover:border-primary-500 hover:text-primary-500
    cursor-pointer
  `}
`;

export const ScrollPrimaryLink = tw.a`
    lg:mx-0
    px-8 py-3 rounded bg-primary-500 text-gray-100
    hover:bg-primary-700 hover:text-gray-200 focus:shadow-outline
    border-b-0 cursor-pointer
`;

export const LogoLink = styled(ScrollNavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0!`};

  img {
    ${tw`w-[10rem] lg:w-[8rem] xl:w-[13rem] mr-3`}
  }
`;

export const MobileNavLinksContainer = tw.nav`flex flex-1 items-center justify-between z-50`;
export const NavToggle = tw.button`
  lg:hidden z-20 focus:outline-none hocus:text-primary-500 transition duration-300
`;
export const MobileNavLinks = motion(styled.div`
  ${tw`lg:hidden z-10 fixed top-0 inset-x-0 mx-4 my-6 p-8 border text-center rounded-lg text-gray-900 bg-white`}
  ${NavLinks} {
    ${tw`flex flex-col items-center`}
  }
`);

export const DesktopNavLinks = tw.nav`
  hidden lg:flex flex-1 justify-between items-center
`;

export default ({
  roundedHeaderButton = false,
  logoLink,
  links,
  className,
  buttonRounded = true,
  collapseBreakpointClass = "lg",
}) => {
  const buttonRoundedCss = buttonRounded && tw`rounded-full`;

  const { showNavLinks, animation, toggleNavbar } = useAnimatedNavToggler();
  const collapseBreakpointCss =
    collapseBreakPointCssMap[collapseBreakpointClass];

  useEffect(() => {
    if (showNavLinks) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "visible";
    }
    return () => {
      document.body.style.overflowY = "visible"; // Asegura el estado inicial
    };
  }, [showNavLinks]);

  const defaultLogoLink = (
    <LogoLink to="hero" title="Home">
      <img
        src={logo}
        alt="Metropolitan Cleaning Services logo. New York & New Jersey"
      />
    </LogoLink>
  );

  const navLinksMobile = [
    <NavLinks key={1}>
      <ScrollNavLink
        onClick={() => {
          toggleNavbar(); // Close mobile nav
          document.body.style.overflowY = "visible"; // Restore scroll
        }}
        title="services"
        to="services"
        href="#services"
        {...scrollConfig}
      >
        Services
      </ScrollNavLink>
      <ScrollNavLink
        onClick={() => {
          toggleNavbar(); // Close mobile nav
          document.body.style.overflowY = "visible"; // Restore scroll
        }}
        title="about"
        to="about"
        href="#about"
        {...scrollConfig}
      >
        About us
      </ScrollNavLink>
      <ScrollNavLink
        onClick={() => {
          toggleNavbar(); // Close mobile nav
          document.body.style.overflowY = "visible"; // Restore scroll
        }}
        title="work"
        to="work"
        href="#work"
        {...scrollConfig}
      >
        Our work
      </ScrollNavLink>
      <ScrollNavLink
        onClick={() => {
          toggleNavbar(); // Close mobile nav
          document.body.style.overflowY = "visible"; // Restore scroll
        }}
        title="testimonials"
        to="testimonials"
        href="#testimonials"
        {...scrollConfig}
      >
        Testimonials
      </ScrollNavLink>
      <ScrollNavLink
        onClick={() => {
          toggleNavbar(); // Close mobile nav
          document.body.style.overflowY = "visible"; // Restore scroll
        }}
        title="faq"
        to="faq"
        href="#faq"
        {...scrollConfig}
      >
        FAQ
      </ScrollNavLink>
      <ScrollNavLink
        onClick={() => {
          toggleNavbar(); // Close mobile nav
          document.body.style.overflowY = "visible"; // Restore scroll
        }}
        title="quote"
        to="quote"
        href="#quote"
        {...scrollConfig}
      >
        Free quote
      </ScrollNavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <ScrollPrimaryLink href="tel:+19085405092" css={buttonRoundedCss}>
        Call us
      </ScrollPrimaryLink>
    </NavLinks>,
  ];

  const navLinksDesktop = [
    <NavLinks key={1}>
      <ScrollNavLink
        title="services"
        to="services"
        href="#services"
        {...scrollConfig}
      >
        Services
      </ScrollNavLink>
      <ScrollNavLink title="about" to="about" href="#about" {...scrollConfig}>
        About us
      </ScrollNavLink>
      <ScrollNavLink title="work" to="work" href="#work" {...scrollConfig}>
        Our work
      </ScrollNavLink>
      <ScrollNavLink
        title="testimonials"
        to="testimonials"
        href="#testimonials"
        {...scrollConfig}
      >
        Testimonials
      </ScrollNavLink>
      <ScrollNavLink title="faq" to="faq" href="#faq" {...scrollConfig}>
        FAQ
      </ScrollNavLink>
      <ScrollNavLink title="quote" to="quote" href="#quote" {...scrollConfig}>
        Free quote
      </ScrollNavLink>
    </NavLinks>,
    <NavLinks key={2}>
      <ScrollPrimaryLink href="tel:+19085405092" css={buttonRoundedCss}>
        Call us
      </ScrollPrimaryLink>
    </NavLinks>,
  ];

  logoLink = logoLink || defaultLogoLink;

  return (
    <Header className={className || "header-light"}>
      <DesktopNavLinks css={collapseBreakpointCss.desktopNavLinks}>
        {logoLink}
        {navLinksDesktop}
      </DesktopNavLinks>

      <MobileNavLinksContainer
        css={collapseBreakpointCss.mobileNavLinksContainer}
      >
        {logoLink}
        <MobileNavLinks
          initial={{ x: "150%", display: "none" }}
          animate={animation}
          css={collapseBreakpointCss.mobileNavLinks}
        >
          {navLinksMobile}
        </MobileNavLinks>
        <NavToggle
          onClick={toggleNavbar}
          aria-label="Menu"
          className={showNavLinks ? "open" : "closed"}
        >
          {showNavLinks ? (
            <CloseIcon tw="w-6 h-6" aria-label="Close menu" />
          ) : (
            <MenuIcon tw="w-6 h-6" aria-label="Open menu" />
          )}
        </NavToggle>
      </MobileNavLinksContainer>
    </Header>
  );
};

/* The below code is for generating dynamic break points for navbar.
 * Using this you can specify if you want to switch
 * to the toggleable mobile navbar at "sm", "md" or "lg" or "xl" above using the collapseBreakpointClass prop
 * Its written like this because we are using macros and we can not insert dynamic variables in macros
 */

const collapseBreakPointCssMap = {
  sm: {
    mobileNavLinks: tw`sm:hidden`,
    desktopNavLinks: tw`sm:flex`,
    mobileNavLinksContainer: tw`sm:hidden`,
  },
  md: {
    mobileNavLinks: tw`md:hidden`,
    desktopNavLinks: tw`md:flex`,
    mobileNavLinksContainer: tw`md:hidden`,
  },
  lg: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
  xl: {
    mobileNavLinks: tw`lg:hidden`,
    desktopNavLinks: tw`lg:flex`,
    mobileNavLinksContainer: tw`lg:hidden`,
  },
};
